<template>
  <layout :loading="loading">
    <template v-slot:content>
      <div class="flex justify-between items-center mt-5">
        <div class="flex items-center">
          <label class="w-16 font-semibold">Week</label>
          <t-select
            class="w-44"
            v-model="weekFilter"
            :options="weeks"
          ></t-select>
        </div>
        <button class="mx-4 text-sm text-blue-500" @click="modal = true">
          <i class="fas fa-question-circle"></i>
          {{ $t("help") }}
        </button>
      </div>

      <h1 class="text-xl mt-3 mb-5">{{ $t("full_timers") }}</h1>
      <h3 class="text-md mt-5 mb-2">{{ $t("kitchen") }}</h3>
      <CheckRoosterTable
        :headers="headers"
        :users="fullTimeKitchen"
        :dates="dates"
      />
      <h3 class="text-md mt-5 mb-2">{{ $t("non_kitchen") }}</h3>
      <CheckRoosterTable
        :headers="headers"
        :users="fullTimeNonKitchen"
        :dates="dates"
      />

      <h3 class="text-md mt-5 mb-2">{{ $t("dishes") }}</h3>
      <CheckRoosterTable
        :headers="headers"
        :users="fullTimeDishes"
        :dates="dates"
      />

      <h1 class="text-xl mt-3 mb-5">{{ $t("part_timers") }}</h1>
      <h3 class="text-md mt-5 mb-2">{{ $t("kitchen") }}</h3>
      <CheckRoosterTable
        :headers="headers"
        :users="partTimeKitchen"
        :dates="dates"
      />
      <h3 class="text-md mt-5 mb-2">{{ $t("non_kitchen") }}</h3>
      <CheckRoosterTable
        :headers="headers"
        :users="partTimeNonKitchen"
        :dates="dates"
      />

      <h3 class="text-md mt-5 mb-2">{{ $t("dishes") }}</h3>
      <CheckRoosterTable
        :headers="headers"
        :users="partTimeDishes"
        :dates="dates"
      />

      <LegendModal v-model="modal"></LegendModal>
    </template>
  </layout>
</template>

<script>
import CheckRoosterTable from "@/components/CheckRoosterTable.vue";
import Layout from "@/components/Layout.vue";
import LegendModal from "@/components/LegendModal.vue";
import { getters } from "@/constants/state";
import { getKitchenUsers } from "@/services/user-service";
import { getWeekDatesFromDate, getWeekOptions } from "@/utils/misc";
import { get } from "lodash";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  components: { Layout, CheckRoosterTable, LegendModal },
  data: () => ({
    fullTimeKitchen: [],
    fullTimeNonKitchen: [],
    fullTimeDishes: [],
    partTimeKitchen: [],
    partTimeNonKitchen: [],
    partTimeDishes: [],
    weekFilter: moment().startOf("week").add(1, "d").format("YYYY-MM-DD"),
    modal: false,
    loading: false,
  }),
  async mounted() {
    this.getUsers(this.weekFilter);
  },
  methods: {
    async getUsers(date) {
      try {
        this.loading = true;
        const { data } = await getKitchenUsers(date);
        this.fullTimeKitchen = this.sorted(data.fullTimeKitchen);
        this.fullTimeNonKitchen = this.sorted(data.fullTimeNonKitchen);
        this.fullTimeDishes = this.sorted(data.fullTimeDishes);
        this.partTimeKitchen = this.sorted(data.partTimeKitchen);
        this.partTimeNonKitchen = this.sorted(data.partTimeNonKitchen);
        this.partTimeDishes = this.sorted(data.partTimeDishes);
      } catch (error) {
        console.error("getKitchenUsers", error);
      }

      this.loading = false;
    },
    sorted(data) {
      const sorted = [];
      Object.keys(data).forEach((key) =>
        sorted.push({
          name: key,
          ...data[key],
        })
      );

      return sorted.sort((a, b) => a.name.localeCompare(b.name));
    },
    isAvailable(data, date) {
      return get(data, `${date}.times.available`, false) || "text-red-500";
    },
  },
  computed: {
    ...mapGetters({
      isAdminOrSuperUser: getters.AUTH_IS_ADMIN_OR_SUPERUSER_GETTER,
    }),
    weeks() {
      return getWeekOptions(this.isAdminOrSuperUser);
    },
    dates() {
      return Object.values(getWeekDatesFromDate(this.weekFilter));
    },
    headers() {
      return [
        "",
        ...this.dates,
        this.$i18n.t("total"),
        this.$i18n.t("available_days_a_week"),
      ];
    },
  },
  watch: {
    weekFilter: function (value) {
      this.getUsers(value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
